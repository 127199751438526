import * as EVENTS_TYPES from '../types/eventsTypes'

const INITIAL_STATE = {
  error: '',
  loading: true,
  tokenSaleDetails: {},
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EVENTS_TYPES.GET_TOKEN_SALE_DETAILS_REQUEST:
      return { ...state, loading: true }
    case EVENTS_TYPES.GET_TOKEN_SALE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        tokenSaleDetails: action.payload,
      }
    case EVENTS_TYPES.GET_TOKEN_SALE_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export default reducer
