import * as KYC_TYPES from '../types/kycTypes'

const INITIAL_STATE = {
  error: '',
  loading: false,
  verification: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KYC_TYPES.GET_KYC_INFO_REQUEST:
      return { ...state, loading: true }
    case KYC_TYPES.GET_KYC_INFO_SUCCESS:
      return { ...state, loading: false, verification: action.payload }
    case KYC_TYPES.GET_KYC_INFO_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}

export default reducer
