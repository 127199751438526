import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import ReduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createRootReducer from '../modules/reducers'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
// This is to blacklist an entire reducer including its nested keys
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['app', 'transactions'],
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const middlewares = [ReduxThunk, routerMiddleware(history)]
const enhancers = [applyMiddleware(...middlewares)]
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
      })
    : compose

export const store = createStore(persistedReducer, {}, composeEnhancers(...enhancers))
export const persistor = persistStore(store)
