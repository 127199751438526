import React, { Component, Suspense } from 'react'
import firebase from 'firebase'
import { CSpinner } from '@coreui/react-pro'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { history, store, persistor } from './modules/store'
import Router from './modules/router/Router'
import firebaseConfig from './firebase.json'
import 'react-toastify/dist/ReactToastify.css'

class App extends Component {
  UNSAFE_componentWillMount() {
    this.initializeFirebase()
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<CSpinner className={'app-spinner centered-position'} />}>
              {this.renderToastContainer()}
              <Router />
            </Suspense>
          </PersistGate>
        </ConnectedRouter>
      </Provider>
    )
  }

  renderToastContainer = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    )
  }

  initializeFirebase = () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
  }
}

export default App
