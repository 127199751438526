import * as AUTH_TYPES from '../types/authTypes'

const INITIAL_STATE = {
  error: '',
  loading: false,
  user: null,
  authToken: '',
  emailCode: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_TYPES.SIGN_IN_REQUEST:
      return { ...state, ...INITIAL_STATE, loading: true }
    case AUTH_TYPES.SIGN_IN_SUCCESS:
      return { ...state, ...INITIAL_STATE, loading: false, user: action.payload }
    case AUTH_TYPES.SIGN_IN_FAILURE:
      return { ...state, ...INITIAL_STATE, loading: false, error: action.payload }

    case AUTH_TYPES.VERIFY_USER_ACCOUNT_INFO_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.VERIFY_USER_ACCOUNT_INFO_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case AUTH_TYPES.VERIFY_USER_ACCOUNT_INFO_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.UPDATE_USER_ACCOUNT_INFO_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.UPDATE_USER_ACCOUNT_INFO_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case AUTH_TYPES.UPDATE_USER_ACCOUNT_INFO_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.GENERATE_CODE_AND_SEND_EMAIL_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.GENERATE_CODE_AND_SEND_EMAIL_SUCCESS:
      return { ...state, loading: false, emailCode: action.payload }
    case AUTH_TYPES.GENERATE_CODE_AND_SEND_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.VERIFY_EMAIL_ADDRESS_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.VERIFY_EMAIL_ADDRESS_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case AUTH_TYPES.VERIFY_EMAIL_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.UPLOAD_IDENTIFICATION_IMAGE_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.UPLOAD_IDENTIFICATION_IMAGE_SUCCESS:
      return { ...state, loading: false, user: action.payload }
    case AUTH_TYPES.UPLOAD_IDENTIFICATION_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.STORE_AUTH_TOKEN:
      return { ...state, authToken: action.payload }

    case AUTH_TYPES.SET_INITIAL_STATE:
    case AUTH_TYPES.SIGN_OUT_USER:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
