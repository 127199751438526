import * as INVESTOR_TYPES from '../types/investorsTypes'
import * as AUTH_TYPES from '../types/authTypes'

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: '',
  loading: false,
  investorList: null,
  selectedInvestor: null,
  selectedInvestor_xlmBalance: 0,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INVESTOR_TYPES.GET_INVESTORS_REQUEST:
      return { ...state, loading: true, error: '' }
    case INVESTOR_TYPES.GET_INVESTORS_SUCCESS:
      return { ...state, loading: false, investorList: action.payload, error: '' }
    case INVESTOR_TYPES.GET_INVESTORS_SUCCESS_USER_NOT_ADMIN:
      return { ...state, loading: false, investorList: null, error: '' }
    case INVESTOR_TYPES.GET_INVESTORS_SUCCESS_UPDATING_SELECTED_INVESTOR:
      return {
        ...state,
        loading: false,
        investorList: action.investorList,
        selectedInvestor: action.selectedInvestor,
        error: '',
      }
    case INVESTOR_TYPES.GET_INVESTORS_FAILURE:
      return { ...state, loading: false, error: action.error }

    case INVESTOR_TYPES.SELECT_INVESTOR:
      return { ...state, loading: false, selectedInvestor: action.payload, error: '' }
    case INVESTOR_TYPES.CLEAR_SELECTED_INVESTOR:
      return { ...state, loading: false, selectedInvestor: null, error: '' }

    case INVESTOR_TYPES.UPDATE_INVESTOR_REQUEST:
      return { ...state, loading: true, error: '' }
    case INVESTOR_TYPES.UPDATE_INVESTOR_SUCCESS:
      return { ...state, loading: false, selectedInvestor: action.payload, error: '' }
    case INVESTOR_TYPES.UPDATE_INVESTOR_FAILURE:
      return { ...state, loading: false, error: action.error }

    case INVESTOR_TYPES.UPDATE_INVESTOR_ACCOUNT_STATUS_REQUEST:
      return { ...state, loading: true, error: '' }
    case INVESTOR_TYPES.UPDATE_INVESTOR_ACCOUNT_STATUS_SUCCESS:
      return { ...state, loading: false, selectedInvestor: action.payload, error: '' }
    case INVESTOR_TYPES.UPDATE_INVESTOR_ACCOUNT_STATUS_FAILURE:
      return { ...state, loading: false, error: action.error }

    case INVESTOR_TYPES.DELETE_INVESTOR_IMAGE_REQUEST:
      return { ...state, loading: true, error: '' }
    case INVESTOR_TYPES.DELETE_INVESTOR_IMAGE_SUCCESS:
      return { ...state, loading: false, selectedInvestor: action.payload, error: '' }
    case INVESTOR_TYPES.DELETE_INVESTOR_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.error }

    case INVESTOR_TYPES.GET_XLM_BALANCE_REQUEST:
      return { ...state, loading: true }
    case INVESTOR_TYPES.GET_XLM_BALANCE_SUCCESS:
      return { ...state, loading: false, selectedInvestor_xlmBalance: action.payload, error: '' }
    case INVESTOR_TYPES.GET_XLM_BALANCE_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.SIGN_OUT_USER:
    case AUTH_TYPES.SET_INITIAL_STATE:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
