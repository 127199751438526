import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('../../layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('../../views/pages/login/Login'))
const Register = React.lazy(() => import('../../views/pages/register/Register'))
const ForgotPassword = React.lazy(() => import('../../views/pages/forgot-password/ForgotPassword'))
const Page404 = React.lazy(() => import('../../views/pages/page404/Page404'))

class RouterComponent extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      user: PropTypes.object,
    }),
  }

  render() {
    const privateRouteProps = {
      component: DefaultLayout,
      path: '/',
    }

    return (
      <Switch>
        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) => <Register {...props} />}
        />
        <Route
          exact
          path="/forgot-password"
          name="Forgot Password Page"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
        {this.renderPrivateRoute(privateRouteProps)}
      </Switch>
    )
  }

  renderPrivateRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => this.handleRoute(props, Component)} />
  }

  handleRoute = (props, Component) => {
    const isAuthenticated = this.props.auth.user

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(RouterComponent)
