import * as AUTH_TYPES from '../types/transactionsTypes'
import utils from '@mindbush/artomos-shared-repo/utils'
import { vestingDistributionTypes } from '@mindbush/artomos-shared-repo/schemas'

// =======================================
// Schemas
// =======================================

const vestingDistributionListSchema = {
  type: ['array', 'null'],
  optional: true,
  items: [
    {
      type: 'object',
      properties: { ...vestingDistributionTypes },
    },
  ],
}

const transactionsSchema = {
  type: 'object',
  properties: {
    error: { type: 'string', optional: true },
    loading: { type: 'boolean' },
    tokenSale2021: { type: ['object', 'null'] },
    vestingDistributionList: vestingDistributionListSchema,
  },
}

// =======================================
// Reducer
// =======================================

const INITIAL_STATE = {
  error: '',
  loading: false,
  tokenSale2021: null,
  vestingDistributionList: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  utils.validateReduxSchema(transactionsSchema, state)

  switch (action.type) {
    case AUTH_TYPES.CREATE_CRYPTO_TRANSACTION_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.CREATE_CRYPTO_TRANSACTION_SUCCESS:
      return { ...state, loading: false, tokenSale2021: action.payload, error: '' }
    case AUTH_TYPES.CREATE_CRYPTO_TRANSACTION_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.GET_TRANSACTIONS_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.GET_TRANSACTIONS_SUCCESS:
      return { ...state, loading: false, tokenSale2021: action.payload, error: '' }
    case AUTH_TYPES.GET_TRANSACTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.GENERATE_VESTING_DISTRIBUTION_LIST_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.GENERATE_VESTING_DISTRIBUTION_LIST_SUCCESS:
      return { ...state, loading: false, vestingDistributionList: action.payload, error: '' }
    case AUTH_TYPES.GENERATE_VESTING_DISTRIBUTION_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload }

    case AUTH_TYPES.DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_REQUEST:
      return { ...state, loading: true }
    case AUTH_TYPES.DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_SUCCESS:
      return { ...state, loading: false, error: '' }
    case AUTH_TYPES.DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export default reducer
