export const CREATE_CRYPTO_TRANSACTION_SUCCESS = 'CREATE_CRYPTO_TRANSACTION_SUCCESS'
export const CREATE_CRYPTO_TRANSACTION_FAILURE = 'CREATE_CRYPTO_TRANSACTION_FAILURE'
export const CREATE_CRYPTO_TRANSACTION_REQUEST = 'CREATE_CRYPTO_TRANSACTION_REQUEST'

export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE'
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST'

export const GENERATE_VESTING_DISTRIBUTION_LIST_SUCCESS =
  'GENERATE_VESTING_DISTRIBUTION_LIST_SUCCESS'
export const GENERATE_VESTING_DISTRIBUTION_LIST_FAILURE =
  'GENERATE_VESTING_DISTRIBUTION_LIST_FAILURE'
export const GENERATE_VESTING_DISTRIBUTION_LIST_REQUEST =
  'GENERATE_VESTING_DISTRIBUTION_LIST_REQUEST'

export const DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_SUCCESS =
  'DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_SUCCESS'
export const DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_FAILURE =
  'DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_FAILURE'
export const DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_REQUEST =
  'DIST_TOKENS_TO_INVESTORS_N_XLM_TO_POOL_REQUEST'
