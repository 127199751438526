import englishStrings from '../../strings/en.json'

const INITIAL_STATE = {
  error: '',
  strings: englishStrings,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
