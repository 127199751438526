import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './authReducers'
import app from './appReducers'
import events from './eventsReducers'
import transactions from './transactionsReducers'
import investors from './investorsReducers'
import kyc from './kycReducers'

const initialState = {
  sidebarShow: false,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// This is to blacklist specific keys within a reducer
const transactionsConfig = {
  key: 'transactions',
  storage,
  blacklist: ['vestingDistributionList', 'loading', 'error'],
}

const createRootReducer = (history) =>
  combineReducers({
    changeState,
    auth,
    app,
    events,
    transactions: persistReducer(transactionsConfig, transactions),
    investors,
    kyc,
    router: connectRouter(history),
  })

export default createRootReducer
