export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

export const STORE_AUTH_TOKEN = 'STORE_AUTH_TOKEN'

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'

export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'SEND_PASSWORD_RESET_EMAIL_SUCCESS'
export const SEND_PASSWORD_RESET_EMAIL_FAILURE = 'SEND_PASSWORD_RESET_EMAIL_FAILURE'
export const SEND_PASSWORD_RESET_EMAIL_REQUEST = 'SEND_PASSWORD_RESET_EMAIL_REQUEST'

export const VERIFY_USER_ACCOUNT_INFO_SUCCESS = 'VERIFY_USER_ACCOUNT_INFO_SUCCESS'
export const VERIFY_USER_ACCOUNT_INFO_FAILURE = 'VERIFY_USER_ACCOUNT_INFO_FAILURE'
export const VERIFY_USER_ACCOUNT_INFO_REQUEST = 'VERIFY_USER_ACCOUNT_INFO_REQUEST'

export const UPDATE_USER_ACCOUNT_INFO_SUCCESS = 'UPDATE_USER_ACCOUNT_INFO_SUCCESS'
export const UPDATE_USER_ACCOUNT_INFO_FAILURE = 'UPDATE_USER_ACCOUNT_INFO_FAILURE'
export const UPDATE_USER_ACCOUNT_INFO_REQUEST = 'UPDATE_USER_ACCOUNT_INFO_REQUEST'

export const GENERATE_CODE_AND_SEND_EMAIL_SUCCESS = 'GENERATE_CODE_AND_SEND_EMAIL_SUCCESS'
export const GENERATE_CODE_AND_SEND_EMAIL_FAILURE = 'GENERATE_CODE_AND_SEND_EMAIL_FAILURE'
export const GENERATE_CODE_AND_SEND_EMAIL_REQUEST = 'GENERATE_CODE_AND_SEND_EMAIL_REQUEST'

export const VERIFY_EMAIL_ADDRESS_SUCCESS = 'VERIFY_EMAIL_ADDRESS_SUCCESS'
export const VERIFY_EMAIL_ADDRESS_FAILURE = 'VERIFY_EMAIL_ADDRESS_FAILURE'
export const VERIFY_EMAIL_ADDRESS_REQUEST = 'VERIFY_EMAIL_ADDRESS_REQUEST'

export const UPLOAD_IDENTIFICATION_IMAGE_SUCCESS = 'UPLOAD_IDENTIFICATION_IMAGE_SUCCESS'
export const UPLOAD_IDENTIFICATION_IMAGE_FAILURE = 'UPLOAD_IDENTIFICATION_IMAGE_FAILURE'
export const UPLOAD_IDENTIFICATION_IMAGE_REQUEST = 'UPLOAD_IDENTIFICATION_IMAGE_REQUEST'
