export const GET_INVESTORS_REQUEST = 'GET_INVESTORS_REQUEST'
export const GET_INVESTORS_SUCCESS = 'GET_INVESTORS_SUCCESS'
export const GET_INVESTORS_SUCCESS_USER_NOT_ADMIN = 'GET_INVESTORS_SUCCESS_USER_NOT_ADMIN'
export const GET_INVESTORS_SUCCESS_UPDATING_SELECTED_INVESTOR =
  'GET_INVESTORS_SUCCESS_UPDATING_SELECTED_INVESTOR'
export const GET_INVESTORS_FAILURE = 'GET_INVESTORS_FAILURE'

export const SELECT_INVESTOR = 'SELECT_INVESTOR'
export const CLEAR_SELECTED_INVESTOR = 'CLEAR_SELECTED_INVESTOR'

export const UPDATE_INVESTOR_REQUEST = 'UPDATE_INVESTOR_REQUEST'
export const UPDATE_INVESTOR_SUCCESS = 'UPDATE_INVESTOR_SUCCESS'
export const UPDATE_INVESTOR_FAILURE = 'UPDATE_INVESTOR_FAILURE'

export const UPDATE_INVESTOR_ACCOUNT_STATUS_REQUEST = 'UPDATE_INVESTOR_ACCOUNT_STATUS_REQUEST'
export const UPDATE_INVESTOR_ACCOUNT_STATUS_SUCCESS = 'UPDATE_INVESTOR_ACCOUNT_STATUS_SUCCESS'
export const UPDATE_INVESTOR_ACCOUNT_STATUS_FAILURE = 'UPDATE_INVESTOR_ACCOUNT_STATUS_FAILURE'

export const DELETE_INVESTOR_IMAGE_REQUEST = 'DELETE_INVESTOR_IMAGE_REQUEST'
export const DELETE_INVESTOR_IMAGE_SUCCESS = 'DELETE_INVESTOR_IMAGE_SUCCESS'
export const DELETE_INVESTOR_IMAGE_FAILURE = 'DELETE_INVESTOR_IMAGE_FAILURE'

export const GET_XLM_BALANCE_SUCCESS = 'GET_XLM_BALANCE_SUCCESS'
export const GET_XLM_BALANCE_FAILURE = 'GET_XLM_BALANCE_FAILURE'
export const GET_XLM_BALANCE_REQUEST = 'GET_XLM_BALANCE_REQUEST'
